import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  TextField,
  Autocomplete,
  Paper,
  FormHelperText,
  styled,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRef, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PhoneInput from "react-phone-input-2";
import { makeStyles } from "@mui/styles";

import { useSnackbar } from "notistack";
import { useEffect } from "react";
import Iconify from "../../components/Iconify";
// import { s3baseUrl } from "src/config/config";
const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
let info = {
  title: "",
  image: "",
  status: true,
  minBed: "",
  minPrice: "",
  maxPrice: "",
};
const AddOrUpdateProperties = ({ type }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { params } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setProfileImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [timeZoneValue, setTimeZoneValue] = useState("Europe/Dublin");
  const [inputs, setInputs] = useState(info);

  const handleChange = (e) => {
    let name = e.target.name;
    let newValue = e.target.value;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: newValue,
      };
    });
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    // formData.append(
    //   "commission_on_transaction",
    //   inputs.commission_on_transaction
    // );
    // formData.append("commission_percentage", inputs.commission_percentage);
    // setIsLoading(true);
    // const result =
    //   type == "edit"
    //     ? await edit_sale_team_member(formData, state._id)
    //     : await add_sale_team_member(formData);
    // if (result.code === 200) {
    //   enqueueSnackbar(result.message, { variant: "success" });
    //   navigate(-1);
    // } else {
    //   enqueueSnackbar(result.message, { variant: "error" });
    //   setIsLoading(false);
    // }
  };
  useEffect(() => {
    if (state) {
      setInputs(state);
    }
  }, [state]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex ">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-2">{`${
            type == "edit" ? "Edit" : "Add"
          } Properties`}</h2>
        </div>
        <form
        //  onSubmit={handleSubmit}
        >
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                fullWidth
                name="title"
                required
                value={inputs.title}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Min Bad"
                variant="outlined"
                fullWidth
                name="minBed"
                required
                value={inputs.minBed}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Min Price"
                variant="outlined"
                fullWidth
                name="minPrice"
                required
                value={inputs.minPrice}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Max Price"
                variant="outlined"
                fullWidth
                name="maxPrice"
                required
                value={inputs.maxPrice}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  label="Status *"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <div className="row w-100 div-style ms-0 pt-0">
                <div className="col-5">
                  <p className="">Upload Image *</p>
                  <FormHelperText className="pt-0">
                    Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {file ? (
                    <img src={file} height="50" />
                  ) : (
                    <>
                      {inputs.image.thumbnail_1 && (
                        <img src={inputs.image.thumbnail_1} height="50" />
                      )}
                    </>
                  )}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Button
                      startIcon={<FileUploadIcon />}
                      component="span"
                      className="capitalized "
                      style={{ border: "2px solid", padding: "10px" }}
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
              {inputs.image.name == "" ? null : (
                <p className="text-secondary">{inputs.image.name}</p>
              )}
            </div>
            <div className="text-end ms-auto">
              <Button variant="contained">
                {type == "edit" ? "Update" : "Submit"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOrUpdateProperties;
