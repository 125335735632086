import React, { useEffect } from "react";
import SummaryCard from "./components/SummaryCard";
import { useAdminContext } from "../../Hooks/AdminContext";
import ReactApexChartDashboard from "./components/ReactApexChartDashboard";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { setnavbarTitle } = useAdminContext();
  const ative_user = localStorage.getItem("token");
  const navigate = useNavigate();

  const DUMMY_DATA = [
    {
      color: "#5792c9",
      title: "Total Users",

      count: "450",
      icon: "ri:group-line",
    },
    {
      color: "#5792c9",
      title: "Total Companies",
      count: "50",

      icon: "mingcute:building-6-line",
    },
    {
      color: "#5792c9",
      title: "Top Companies ",
      count: "10",
      icon: "uiw:map",
    },
    {
      color: "#5792c9",
      title: "TotalCities",
      count: "63,786",
      icon: "arcticons:50-us-states-map",
    },
  ];

  useEffect(() => {
    setnavbarTitle("DashBoard");
    if (
      localStorage.getItem("token") == "" ||
      localStorage.getItem("token") == null
    ) {
      navigate("/login");
    }
  }, [localStorage]);

  return (
    <>
      <div className="row">
        {DUMMY_DATA.map((item, i) => (
          <div key={i} className="col-12 col-md-6 col-lg-3">
            <SummaryCard
              color={item.color}
              title={item.title}
              count={item.count}
              icon={item.icon}
            />
          </div>
        ))}
      </div>

      <div className="row mt-2">
        <ReactApexChartDashboard />
      </div>
    </>
  );
};

export default Dashboard;
