import { invokeApi } from "../invokeApi";

export const AddProperty = async (data) => {
  const requestObj = {
    path: `api/properties`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UpdateProperty = async (data, id) => {
  const requestObj = {
    path: `api/properties/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteProperty = async (id) => {
  const requestObj = {
    path: `api/properties/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const ListProperty = async () => {
  const requestObj = {
    path: `api/properties`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
