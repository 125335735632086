import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  TextField,
  Autocomplete,
  Paper,
  FormHelperText,
  styled,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRef, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PhoneInput from "react-phone-input-2";
import { makeStyles } from "@mui/styles";
import Editor from "../../components/editor/Editor";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import Iconify from "../../components/Iconify";
import { uploadImageOns3 } from "../../DAL/commonApi/commonApi";
import { picBaseUrl } from "../../config/config";
import { Getwebsite, UPdatePage } from "../../DAL/PagesApi/PagesApi";
// import { s3baseUrl } from "src/config/config";
const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
let info = {
  main_page_heading: "",
  main_page_button_text: "",
  our_services_heading_text: "",
  feature_properties_services_heading_text: "",
  how_much_property_worth_button_text: "",
  register_with_us_button_text: "",
  all_rigths_reserved_text: "",
  header_logo: "",
  main_page_background_image: "",
  wellcome_image: "",
  download_section_image: "",
  how_much_property_worth_image: "",
  register_with_us_image: "",
  wellcome_state_description: "",
  download_section_description: "",
  how_much_property_description: "",
  register_with_us_description: "",
  get_in_touch: "",
  opening_hours: "",
  useful_pages: "",
  get_in_touch_des: "",
  opening_hours_des: "",

  status: true,
};
const HomePage = ({ type }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { params } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setProfileImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [timeZoneValue, setTimeZoneValue] = useState("Europe/Dublin");
  const [inputs, setInputs] = useState(info);

  const handleChange = (e) => {
    let name = e.target.name;
    let newValue = e.target.value;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: newValue,
      };
    });
  };

  const fileChangedHandler = async (e) => {
    const formData = new FormData();
    const name = e.target.name;
    formData.append("image", e.target.files[0]);
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs((input) => ({
        ...input,
        [name]: imageUpload.image_path,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      website_content: {
        homePage: inputs,
      },
    };
    const result = await UPdatePage(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const GetwebsiteContent = async () => {
    setIsLoading(true);
    const result = await Getwebsite();
    if (result.code == 200) {
      setInputs(result.webpage_content.website_content.homePage);
      console.log(result.webpage_content.website_content.homePage, "vvvvvv");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    GetwebsiteContent();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex ">
          <h2 className="ms-2">Update Home Page Content Settings</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Main Page Heading"
                variant="outlined"
                fullWidth
                name="main_page_heading"
                required
                value={inputs.main_page_heading}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Main Page Button Text"
                variant="outlined"
                fullWidth
                name="main_page_button_text"
                required
                value={inputs.main_page_button_text}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Our Services Heading Text"
                variant="outlined"
                fullWidth
                name="our_services_heading_text"
                required
                value={inputs.our_services_heading_text}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Feature Properties Services Heading Text"
                variant="outlined"
                fullWidth
                name="feature_properties_services_heading_text"
                required
                value={inputs.feature_properties_services_heading_text}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Register With Us Button Text"
                variant="outlined"
                fullWidth
                name="register_with_us_button_text"
                required
                value={inputs.register_with_us_button_text}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="All Rigths Reserved Text"
                variant="outlined"
                fullWidth
                name="all_rigths_reserved_text"
                required
                value={inputs.all_rigths_reserved_text}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="how much property worth button text"
                variant="outlined"
                fullWidth
                name="how_much_property_worth_button_text"
                required
                value={inputs.how_much_property_worth_button_text}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Get In Touch"
                variant="outlined"
                fullWidth
                name="get_in_touch"
                required
                value={inputs.get_in_touch}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Opening Hours"
                variant="outlined"
                fullWidth
                name="opening_hours"
                required
                value={inputs.opening_hours}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Useful Pages"
                variant="outlined"
                fullWidth
                name="useful_pages"
                required
                value={inputs.useful_pages}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  label="Status *"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              {/* Upload Header Logo */}
              <div className="row w-100 div-style ms-0 pt-0">
                <div className="col-5">
                  <p>Upload Header Logo *</p>
                  <FormHelperText className="pt-0">
                    Image Size(1000 X 670) ("JPG", "JPEG", "PNG", "WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {inputs.header_logo && (
                    <img src={picBaseUrl + inputs.header_logo} height="50" />
                  )}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file-header-logo">
                    <Input
                      accept="image/*"
                      id="contained-button-file-header-logo"
                      type="file"
                      name="header_logo"
                      onChange={fileChangedHandler}
                    />
                    <Button
                      startIcon={<FileUploadIcon />}
                      component="span"
                      className="capitalized"
                      style={{ border: "2px solid", padding: "10px" }}
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>

              {/* Upload Main Page Background Image */}
              <div className="row w-100 div-style ms-0 pt-0 mt-4">
                <div className="col-5">
                  <p>Upload Main Page Background Image *</p>
                  <FormHelperText className="pt-0">
                    Image Size(1000 X 670) ("JPG", "JPEG", "PNG", "WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {inputs.main_page_background_image && (
                    <img
                      src={picBaseUrl + inputs.main_page_background_image}
                      height="50"
                    />
                  )}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file-main-page-background-image">
                    <Input
                      accept="image/*"
                      id="contained-button-file-main-page-background-image"
                      type="file"
                      name="main_page_background_image"
                      onChange={fileChangedHandler}
                    />
                    <Button
                      startIcon={<FileUploadIcon />}
                      component="span"
                      className="capitalized"
                      style={{ border: "2px solid", padding: "10px" }}
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>

              {/* Upload Welcome Image */}
              <div className="row w-100 div-style ms-0 pt-0 mt-4">
                <div className="col-5">
                  <p>Upload Welcome Image *</p>
                  <FormHelperText className="pt-0">
                    Image Size(1000 X 670) ("JPG", "JPEG", "PNG", "WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {inputs.wellcome_image && (
                    <img src={picBaseUrl + inputs.wellcome_image} height="50" />
                  )}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file-welcome-image">
                    <Input
                      accept="image/*"
                      id="contained-button-file-welcome-image"
                      type="file"
                      name="wellcome_image"
                      onChange={fileChangedHandler}
                    />
                    <Button
                      startIcon={<FileUploadIcon />}
                      component="span"
                      className="capitalized"
                      style={{ border: "2px solid", padding: "10px" }}
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>

              {/* Upload Download Section Image */}
              <div className="row w-100 div-style ms-0 pt-0 mt-4">
                <div className="col-5">
                  <p>Upload Download Section Image *</p>
                  <FormHelperText className="pt-0">
                    Image Size(1000 X 670) ("JPG", "JPEG", "PNG", "WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {inputs.download_section_image && (
                    <img
                      src={picBaseUrl + inputs.download_section_image}
                      height="50"
                    />
                  )}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file-download-section-image">
                    <Input
                      accept="image/*"
                      id="contained-button-file-download-section-image"
                      type="file"
                      name="download_section_image"
                      onChange={fileChangedHandler}
                    />
                    <Button
                      startIcon={<FileUploadIcon />}
                      component="span"
                      className="capitalized"
                      style={{ border: "2px solid", padding: "10px" }}
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>

              {/* Upload How Much Property Worth Image */}
              <div className="row w-100 div-style ms-0 pt-0 mt-4">
                <div className="col-5">
                  <p>Upload How Much Property Worth Image *</p>
                  <FormHelperText className="pt-0">
                    Image Size(1000 X 670) ("JPG", "JPEG", "PNG", "WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {inputs.how_much_property_worth_image && (
                    <img
                      src={picBaseUrl + inputs.how_much_property_worth_image}
                      height="50"
                    />
                  )}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file-how-much-property-worth-image">
                    <Input
                      accept="image/*"
                      id="contained-button-file-how-much-property-worth-image"
                      type="file"
                      name="how_much_property_worth_image"
                      onChange={fileChangedHandler}
                    />
                    <Button
                      startIcon={<FileUploadIcon />}
                      component="span"
                      className="capitalized"
                      style={{ border: "2px solid", padding: "10px" }}
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>

              {/* Upload Register With Us Image */}
              <div className="row w-100 div-style ms-0 pt-0 mt-4">
                <div className="col-5">
                  <p>Upload Register With Us Image *</p>
                  <FormHelperText className="pt-0">
                    Image Size(1000 X 670) ("JPG", "JPEG", "PNG", "WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {inputs.register_with_us_image && (
                    <img
                      src={picBaseUrl + inputs.register_with_us_image}
                      height="50"
                    />
                  )}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file-register-with-us-image">
                    <Input
                      accept="image/*"
                      id="contained-button-file-register-with-us-image"
                      type="file"
                      name="register_with_us_image"
                      onChange={fileChangedHandler}
                    />
                    <Button
                      startIcon={<FileUploadIcon />}
                      component="span"
                      className="capitalized"
                      style={{ border: "2px solid", padding: "10px" }}
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <h4>Welcome State Description</h4>
              <Editor
                setInputs={setInputs}
                inputs={inputs}
                name="wellcome_state_description"
                editorHeight={320}
              />
            </div>
            <div className="col-12 mt-4">
              <h4>Download Section Description</h4>
              <Editor
                setInputs={setInputs}
                inputs={inputs}
                name="download_section_description"
                editorHeight={320}
              />
            </div>
            <div className="col-12 mt-4">
              <h4>How Much Property Worth Description</h4>
              <Editor
                setInputs={setInputs}
                inputs={inputs}
                name="how_much_property_description"
                editorHeight={320}
              />
            </div>
            <div className="col-12 mt-4">
              <h4>Register With Us Description</h4>
              <Editor
                setInputs={setInputs}
                inputs={inputs}
                name="register_with_us_description"
                editorHeight={320}
              />
            </div>{" "}
            <div className="col-12 mt-4">
              <h4>Get In Touch Description</h4>
              <Editor
                setInputs={setInputs}
                inputs={inputs}
                name="get_in_touch_des"
                editorHeight={320}
              />
            </div>{" "}
            <div className="col-12 mt-4">
              <h4>Opening Hours Description</h4>
              <Editor
                setInputs={setInputs}
                inputs={inputs}
                name="opening_hours_des"
                editorHeight={320}
              />
            </div>
            <div className="text-end ms-auto">
              <Button variant="contained" type="submit">
                {type == "edit" ? "Update" : "Submit"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HomePage;
