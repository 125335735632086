import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { picBaseUrl } from "../../config/config";
import { uploadImageOns3 } from "../../DAL/commonApi/commonApi";
import Editor from "../../components/editor/Editor";
import { AddProperty, UpdateProperty } from "../../DAL/Property/Property";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

export default function AddOrUPdateProperty({ type }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { state } = useLocation();
  const params = useParams();
  const [personName, setPersonName] = useState([]);
  const [member, setMember] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({});
  const [oldImage, setOldImage] = useState();
  const [oldImageArray, setOldImageArray] = useState();
  const [imagesArray, setImagesArray] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [detailDescription, setDetailDescription] = useState("");

  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    short_description: "",
    total_beds: "",
    min_price: "",
    max_price: "",
  });
  const handleSubmit = async (e) => {
    console.log(inputs, "inputsinputsinputs");
    e.preventDefault();
    setIsLoading(true);
    console.log(imagesArray, "from submit");
    if (imagesArray.length < 1) {
      setIsLoading(false);
      enqueueSnackbar("Please select at least 1 image", { variant: "error" });
    } else {
      const results = imagesArray.map((image, index) => {
        const formData = new FormData();
        formData.append("image", image.path);
        const result = uploadImageOns3(formData);
        return result;
      });

      Promise.all(results).then(async (img_results) => {
        let setImageArray = [];
        img_results.map((images, index) => {
          let imageObj = { image: images.image_path };
          setImageArray.push(imageObj);
        });

        let postData = {
          title: inputs.title,
          status: inputs.status,
          short_description: inputs.short_description,
          total_beds: inputs.total_beds,
          min_price: inputs.min_price,
          max_price: inputs.max_price,
          images: setImageArray,
          detail_description: inputs.detail_description,
        };

        const result = params.id
          ? await UpdateProperty(postData, params.id)
          : await AddProperty(postData);
        if (result.code === 200) {
          enqueueSnackbar(result.message, { variant: "success" });
          navigate(-1);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      });
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (imagesArray.length < 1) {
      setIsLoading(false);
      enqueueSnackbar("Please select at least 1 image", { variant: "error" });
    } else {
      var filterArray = oldImageArray.filter((img) => img.type == "file");
      // console.log(filterArray, "filterArrayfilterArrayfilterArray");
      const results = filterArray.map((image, index) => {
        const formData = new FormData();
        formData.append("image", image.path);
        const result = uploadImageOns3(formData);
        return result;
      });
      // console.log(results, "image upload function");
      Promise.all(results).then(async (img_results) => {
        let setImageArray = [];
        img_results.map((images, index) => {
          let imageObj = { image: images.image_path };
          oldImageArray.push(imageObj);
        });
        let sendArray = oldImageArray.map((item, index) => {
          if (item.image) {
            setImageArray.push(item);
          }
        });

        let postData = {
          images: setImageArray,
          title: inputs.title,
          status: inputs.status,
          short_description: inputs.short_description,
          total_beds: inputs.total_beds,
          min_price: inputs.min_price,
          max_price: inputs.max_price,
          detail_description: detailDescription,
        };
        // console.log(postData, "postDatapostDatapostData");

        const result = params.id
          ? await UpdateProperty(postData, params.id)
          : await AddProperty(postData);
        if (result.code === 200) {
          enqueueSnackbar(result.message, { variant: "success" });
          navigate(-1);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleUpload = (event) => {
    let setImageObject = {};
    const fileList = event.target.files[0];
    setImageObject = {
      path: fileList,
      type: "file",
    };
    setImagesArray((prevPreviews) => [...prevPreviews, setImageObject]);
    if (params.id) {
      setOldImageArray((prevPreviewsss) => [...prevPreviewsss, setImageObject]);
    }
  };
  const handleRemove = (index) => {
    imagesArray.splice(index, 1);
    setImagesArray([...imagesArray]);
    setOldImageArray([...imagesArray]);
  };

  useEffect(() => {
    if (params?.id) {
      setInputs(state);
      setDetailDescription(state?.detailed_description);
      setOldImage(state?.video_thumbnail);
      setOldImageArray(state.images);
      setImagesArray(state.images);
    }
  }, []);
  let finalArray = [];
  for (let i = 0; i < personName.length; i++) {
    let count = 0;
    for (let j = 0; j < member.length; j++) {
      if (personName[i]._id == member[j]._id) {
        count++;
      }
    }
    if (count == 0) {
      finalArray.push(personName[i]);
    }
  }

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2">{`${
            type == "edit" ? "Edit" : "Add"
          } Properties`}</h2>
        </div>
      </div>

      <form onSubmit={!!params.id ? handleUpdate : handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              name="title"
              required
              value={inputs.title}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Min Price"
              variant="outlined"
              fullWidth
              name="min_price"
              required
              value={inputs.min_price}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Max Price"
              variant="outlined"
              fullWidth
              name="max_price"
              required
              value={inputs.max_price}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Total Beds"
              variant="outlined"
              fullWidth
              name="total_beds"
              required
              value={inputs.total_beds}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <>
            <div className="col-md-12 mt-4 d-flex  div-style">
              <div className="row w-100 add-memories-preview">
                <div className="col-lg-11 col-md-11 col-sm-12">
                  <div className="row">
                    {imagesArray.length > 0 ? (
                      imagesArray.map((file, index) => (
                        <div
                          className="col-1 mt-2 product-image-width"
                          key={index}
                        >
                          <span className="preview mt-2">
                            <span onClick={() => handleRemove(index)}>x</span>
                            {file.type === "file" ? (
                              <img
                                src={
                                  file.path
                                    ? URL.createObjectURL(file.path)
                                    : ""
                                }
                              />
                            ) : (
                              <img src={picBaseUrl + file.image} />
                            )}
                          </span>
                        </div>
                      ))
                    ) : (
                      <p className="mt-5">Upload Images</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-1 col-md-1 col-sm-12 mt-2">
                  <span className="upload-button mt-2">
                    <input
                      color="primary"
                      accept="image/*"
                      type="file"
                      id="icon-button-file"
                      style={{ display: "none" }}
                      name="affirmationImage"
                      onChange={handleUpload}
                    />
                    <label className="" htmlFor="icon-button-file">
                      <CloudUploadIcon />
                    </label>
                  </span>
                </div>
              </div>
            </div>
            <p className="text-muted">
              Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP") *
            </p>
          </>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description "
              multiline
              style={{ width: "100%" }}
              rows={6}
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Detail Description </h4>
            <Editor
              setInputs={setInputs}
              inputs={inputs}
              name="detail_description"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4">
            <Button variant="contained" type="submit">
              {type == "edit" ? "Update" : "Submit"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
