import { invokeApi } from "../invokeApi";

export const AddPropertyType = async (data) => {
  const requestObj = {
    path: `api/property_types`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UpdatePropertyType = async (data, id) => {
  const requestObj = {
    path: `api/property_types/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeletePropertyType = async (id) => {
  const requestObj = {
    path: `api/property_types/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const ListPropertyType = async () => {
  const requestObj = {
    path: `api/property_types`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
