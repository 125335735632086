import ReactEditor from "react-text-editor-kit";

import { uploadImageOns3 } from "../../DAL/commonApi/commonApi";
import { picBaseUrl } from "../../config/config";

function ReactTextEditorKit({ value, onChange, get_editor_ref }) {
  const image_handler = async (e) => {
    let formData = new FormData();
    formData.append("image", e.image);
    const result = await uploadImageOns3(formData);
    if (result.code === 200) {
      return picBaseUrl + result.image_path;
    } else {
      return "";
    }
  };

  return (
    <div className="App">
      <ReactEditor
        value={value}
        onChange={onChange}
        getEditorRef={get_editor_ref}
        placeholder="Write your text here"
        image_handler={image_handler} // if you want to upload image on your server
      />
    </div>
  );
}

export default ReactTextEditorKit;
