import React, { useRef } from "react";
import ReactTextEditorKit from "./ReactTextEditorKit";
import { picBaseUrl } from "../../config/config";
import { invokeApi } from "../../DAL/invokeApi";

export default function GeneralCkeditor({
  inputs,
  setInputs,
  name,
  editorHeight,
  is_array,
  index,
  value,
}) {
  const editorRef = useRef(null);

  const log = () => {
    if (editorRef.current) {
      if (is_array) {
        const list = [...inputs];
        list[index][name] = editorRef.current.getContent();
        setInputs(list);
      } else if (inputs) {
        setInputs((prevState) => ({
          ...prevState,
          [name]: editorRef.current.getContent(),
        }));
      } else {
        setInputs(editorRef.current.getContent());
      }
    }
  };

  const handleChange = (value) => {
    if (is_array) {
      const list = [...inputs];
      list[index][name] = value;
      setInputs(list);
    } else if (inputs) {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setInputs(value);
    }
  };

  function example_image_upload_handler(blobInfo, success, failure, progress) {
    let requestObj = {
      path: "/app/upload_image/for_editor",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let _formData = new FormData();
    _formData.append("image", blobInfo.blob());
    requestObj["postData"] = _formData;

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        success(picBaseUrl + res.image_path);
      } else {
        failure(res.message);
      }
    });
  }

  const editor_value = () => {
    let value_data = "";
    if (is_array && inputs[index][name]) {
      value_data = inputs[index][name];
    } else if (inputs[name]) {
      value_data = inputs[name];
    } else if (value) {
      value_data = value;
    }
    return value_data;
  };

  return (
    <>
      <ReactTextEditorKit
        onChange={handleChange}
        value={editor_value()}
        get_editor_ref={(ref) => (editorRef.current = ref)}
      />
    </>
  );
}
