import { Button, CircularProgress, Tooltip } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";

import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";

import moment from "moment";
import { useSnackbar } from "notistack";
import CustomTable from "../../components/customTable/CustomTable";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Iconify from "../../components/Iconify";
import { DeleteProperty, ListProperty } from "../../DAL/Property/Property";
import { picBaseUrl } from "../../config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const listings = [
  {
    _id: "1",
    title: "Cozy Apartment",
    minBeds: 1,
    minPrice: 800,
    maxPrice: 1200,
    image: "https://example.com/cozy-apartment.jpg",
    status: true,
  },
  {
    _id: "2",
    title: "Luxury Villa",
    minBeds: 3,
    minPrice: 2500,
    maxPrice: 4000,
    image: "https://example.com/luxury-villa.jpg",
    status: true,
  },
  {
    _id: "3",
    title: "Modern Condo",
    minBeds: 2,
    minPrice: 1500,
    maxPrice: 2200,
    image: "https://example.com/modern-condo.jpg",
    status: false,
  },
  {
    _id: "4",
    title: "Rustic Cabin",
    minBeds: 2,
    minPrice: 900,
    maxPrice: 1500,
    image: "https://example.com/rustic-cabin.jpg",
    status: true,
  },
  {
    _id: "5",
    title: "Urban Loft",
    minBeds: 1,
    minPrice: 1000,
    maxPrice: 1800,
    image: "https://example.com/urban-loft.jpg",
    status: false,
  },
];
const PropertyList = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [teamList, SetTeamList] = useState(listings);
  const [openDelete, setOpenDelete] = useState(false);
  const [filterData, setFilterData] = useState();
  const [filterUpdated, setFilterUpdated] = useState();
  const [filterDrawerState, setFilterDrawerState] = useState(false);

  const getTeamListing = async () => {
    setIsLoading(true);
    const result = await ListProperty();
    if (result.code == 200) {
      console.log(result, "resultresult");
      const team_member = result.properties.map((item) => {
        return {
          ...item,

          table_avatar: {
            src: picBaseUrl + item.images[0].image,
            alt: item.title,
          },
        };
      });
      SetTeamList(team_member);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (value) => {
    navigate(`/property/addproperty/${value?._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteDoc(value);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteProperty(deleteDoc._id);
    if (result.code === 200) {
      SetTeamList((categoriesData) => {
        return categoriesData.filter((data) => data._id !== deleteDoc._id);
      });

      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleNavigate = () => {
    navigate("/property/addproperty");
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title" },
    { id: "table_avatar", label: "mage", type: "thumbnail" },
    { id: "min_price", label: "Min Price" },
    { id: "max_price", label: "Max Price" },
    { id: "total_beds", label: "Total Beds" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  useEffect(() => {
    getTeamListing();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-8">
            <h2>Properties</h2>
          </div>
          <div className="col-4 mt-2  ps-0" style={{ textAlign: "end" }}>
            <div className="d-flex   justify-content-end mb-3 add-button">
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={handleNavigate}
                className="capitalized"
              >
                Add Properties
              </Button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-1 ">
            <CustomTable
              TABLE_HEAD={TABLE_HEAD}
              data={teamList}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
            />
          </div>
        </div>
        <DeleteConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete?"}
          handleAgree={handleDelete}
        />
      </div>
    </>
  );
};

export default PropertyList;
