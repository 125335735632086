import React from "react";
import Iconify from "../../components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={20} height={20} />;

export const SideBarConfig = () => {
  let sidebarMenus = [];

  sidebarMenus.push({
    title: "Dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  });

  sidebarMenus.push({
    title: "Members",
    path: "/users",
    icon: getIcon("fluent-emoji-high-contrast:man-farmer"),
  });
  sidebarMenus.push({
    title: "Services",
    path: "/services",
    icon: getIcon("fluent-emoji-high-contrast:man-farmer"),
  });
  sidebarMenus.push({
    title: "Property Type",
    path: "/property-type",
    icon: getIcon("fluent-emoji-high-contrast:man-farmer"),
  });
  sidebarMenus.push({
    title: "Property",
    path: "/property",
    icon: getIcon("fluent-emoji-high-contrast:man-farmer"),
  });
  sidebarMenus.push({
    title: "Home Page",
    path: "/home-page",
    icon: getIcon("fluent-emoji-high-contrast:man-farmer"),
  });
  sidebarMenus.push({
    title: "About Page",
    path: "/about-page",
    icon: getIcon("fluent-emoji-high-contrast:man-farmer"),
  });
  sidebarMenus.push({
    title: "Contact us page ",
    path: "/contact-page",
    icon: getIcon("fluent-emoji-high-contrast:man-farmer"),
  });

  sidebarMenus.push({
    title: "Logout",
    path: "/login",
    icon: getIcon("humbleicons:logout"),
  });

  return sidebarMenus;
};
