import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { DashboardLayout, LoginLayout } from "../layouts";
import { Dashboard } from "../pages";
import Login from "../pages/Login/Login";
import UsersList from "../pages/Users/UsersList";
import AddOrUpdateUsers from "../pages/Users/AddOrUpdateUsers";
import ServicesList from "../pages/Services/ServicesList";
import AddOrUpdateServices from "../pages/Services/AddOrUpdateServices";
import PropertyList from "../pages/Property/PropertyList";
import AddOrUpdateProperties from "../pages/Property/AddOrUpdateProperties";
import HomePage from "../pages/Pages/HomePage";
import PropertyType from "../pages/PropertyType/PropertyType";
import AddOrUpdatePropertyType from "../pages/PropertyType/AddOrUpdatePropertyType";
import AddOrUPdateProperty from "../pages/Property/AddOrUPdateProperty";
import AboutPage from "../pages/Pages/AboutPage";
import ContactUs from "../pages/Pages/ContactUs";

const Authentication = () => {
  return <Navigate to="/dashboard"> </Navigate>;
};

export default function Routers() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/users" element={<UsersList />} />
        <Route path="/users/addUser" element={<AddOrUpdateUsers />} />
        <Route
          path="/services/addservices"
          element={<AddOrUpdateServices />}
        />{" "}
        <Route
          path="/property-type/add-property-type"
          element={<AddOrUpdatePropertyType />}
        />
        <Route
          path="/services/addservices/:id"
          element={<AddOrUpdateServices type={"edit"} />}
        />{" "}
        <Route
          path="/property-type/add-property-type/:id"
          element={<AddOrUpdatePropertyType type={"edit"} />}
        />
        <Route
          path="/users/addUser/:id"
          element={<AddOrUpdateUsers type={"edit"} />}
        />
        <Route path="/services" element={<ServicesList />} />
        <Route path="/property-type" element={<PropertyType />} />
        <Route path="/property" element={<PropertyList />} />
        {/* <Route
          path="/property/addproperty"
          element={<AddOrUpdateProperties />}
        />{" "} */}
        <Route path="/property/addproperty" element={<AddOrUPdateProperty />} />
        <Route
          path="/property/addproperty/:id"
          element={<AddOrUPdateProperty />}
        />{" "}
        <Route path="/home-page" element={<HomePage />} />
        <Route path="/about-page" element={<AboutPage />} />
        <Route path="/contact-page" element={<ContactUs />} />
      </Route>

      <Route element={<LoginLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
}
