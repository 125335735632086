import { useRef, useState } from "react";
import { Box, Avatar } from "@mui/material";
import { adminIcon } from "../../assets";
import ProfilePopover from "../../components/ProfilePopover";
import Iconify from "../../components/Iconify";
// ----------------------------------------------------------------------

export default function AccountPopover({ setIsLoading }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="mt-4 px-0">
      <div class="d-flex justify-content-between">
        <div class="left-item">
          <Box className="notification-container pointer">
            <Iconify className="notification-bell" icon={"line-md:bell-loop"} />
            <div className="notification-dot"></div>
          </Box>
        </div>
        <div class="right-item ms-3">
          <div className="profile-container" onClick={handleOpen}>
            <div className="profile-avatar">
              <Avatar
                variant="rounded"
                sx={{ width: 40, height: 40 }}
                src={adminIcon}
              />
            </div>
            <div className="profile-info">
              <span className="user-name">Admin</span>
              <span className="user-role">Admin</span>
            </div>

            <span className="chevron-icon pointer">
              <i
                className={`ms-3 me-2 fa-solid ${
                  open ? "fa-chevron-up" : "fa-chevron-down"
                } `}
              ></i>
            </span>
          </div>
        </div>
      </div>

      <ProfilePopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220, borderRadius: 5, border: "1px solid #cecece" }}
      >
        {
          <Box sx={{ my: 1.5, px: 2.5 }} className="profile-container-view">
            <Avatar
              variant="rounded"
              sx={{
                width: 70,
                height: 70,
                mx: "auto",
                borderRadius: 3,
              }}
              src={adminIcon}
            />
            <div className="profile-info-view">
              <span className="user-name-view">Charlie Brown</span>
              <span className="user-email-view">Brown@gmail.com</span>
              <span className="user-role-view">Admin</span>
            </div>
          </Box>
        }
      </ProfilePopover>
    </div>
  );
}
