import { invokeApi } from "../invokeApi";

export const login = async (data) => {
  const requestObj = {
    path: `api/admin_users/admin_login`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
