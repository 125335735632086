import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginImage, loginLogo, logo_2 } from "../../assets";
import LoginForm from "./components/LoginForm";
import EmailForm from "./components/EmailForm";
import PinCodeForm from "./components/PinCodeForm";
import ConfrimPassword from "./components/ConfrimPassword";

const Login = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleMove = () => {
    const handle_move = document.getElementById("handle_move");
    if (handle_move) {
      handle_move.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleSubmitEmail = async (_email) => {
    setFormState(1);
  };

  const handleSubmitPinCode = async (pinCode) => {
    setFormState(2);
  };

  const handleSubmitNewPassword = async (newPassword, confirmPassword) => {
    setFormState(-1);
    navigate("/login");
  };

  return (
    <>
      <div className="full-height-container">
        <div className="text-center ">
          <img
            src={logo_2}
            className="img-fluid logo-main-image"
            alt="Login Logo"
          />
        </div>
        <div className="row g-0 justify-content-center align-content-center">
          <div className="col-md-6 col-lg-4 card custom-card " id="handle_move">
            <div className="p-5 card-body">
              <>
                {formState === 0 ? (
                  <EmailForm
                    handleSubmitEmail={handleSubmitEmail}
                    setErrorMessage={setErrorMessage}
                    handleMove={handleMove}
                    isLoading={isLoading}
                    setFormState={setFormState}
                  />
                ) : formState === 1 ? (
                  <PinCodeForm
                    handleSubmitPinCode={handleSubmitPinCode}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    handleAlertClose={handleAlertClose}
                    setErrorMessage={setErrorMessage}
                    handleMove={handleMove}
                  />
                ) : formState === 2 ? (
                  <ConfrimPassword
                    handleSubmitNewPassword={handleSubmitNewPassword}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    handleAlertClose={handleAlertClose}
                    setErrorMessage={setErrorMessage}
                    handleMove={handleMove}
                  />
                ) : (
                  <LoginForm setFormState={setFormState} />
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
