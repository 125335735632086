import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const ReactApexChartDashboard = () => {
  const labels = [
    { name: "Fertilizer", color: "#f45885" },
    { name: "Seed", color: "#fdb855" },
    { name: "Pesticide", color: "#00e096" },
    { name: "Machinery", color: "#0095ff" },
  ];

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Fertilizer",
        data: [305, 310, 405],
      },
      {
        name: "Seed",
        data: [390, 390, 390],
      },
      {
        name: "Pesticide",
        data: [210, 210, 210],
      },
      {
        name: "Machinery",
        data: [270, 250, 270],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 500,
      },
      colors: ["#f45885", "#fdb855", "#00e096", "#0095ff"],
      grid: {
        show: true,
        borderColor: "#e2e7e7",
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 10, // Set the borderRadius to round the bars
          borderRadiusApplication: "end", // Round the bars at the top
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 5,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Alice Smith", "Bob Johnson", "Charlie Brown"],
        labels: {
          style: {
            colors: "black",
          },
        },
        axisBorder: {
          show: true,
          color: "black",
        },
      },
      fill: {
        opacity: 1,
      },
    },
  });

  return (
    <div className="col-lg-12 ">
      <div className="col-lg-12 apex-chart">
        <div className="d-flex align-items-center w-100 ">
          <div className="position-relative search_input w-100 ">
            <h3 className="header-title"> Users</h3>
          </div>
        </div>

        <div>
          <div id="chart">
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="bar"
              height={350}
            />
          </div>
          <div id="html-dist"></div>
        </div>
      </div>

      {/* <div className="d-flex justify-content-center mt-4">
        {labels.map((label, index) => (
          <div key={index} className="d-flex align-items-center mx-2">
            <span
              className="dot"
              style={{
                backgroundColor: label.color,
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                display: "inline-block",
                marginRight: "5px",
              }}
            ></span>
            {label.name}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default ReactApexChartDashboard;
