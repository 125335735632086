import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Logo, logo_2 } from "../assets";

export default function LoginLayout() {
  return (
    <>
      <Helmet>
        <title>{"Admin Login"}</title>
        <meta name="description" content={"Admin Login"} />
        <link rel="icon" href={logo_2} />
      </Helmet>
      <Outlet />
    </>
  );
}
