import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  TextField,
  FormHelperText,
  styled,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRef, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { uploadImageOns3 } from "../../DAL/commonApi/commonApi";
import { AddMemeber, UpdateMemeber } from "../../DAL/Members/MembersApi";
import { picBaseUrl } from "../../config/config";

const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
let info = {
  name: "",
  email: "",
  password: "",

  profile_image: "",
  status: true,
};
const AddOrUpdateUsers = ({ type }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { params } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setProfileImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState(info);

  const handleChange = (e) => {
    let name = e.target.name;
    let newValue = e.target.value;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: newValue,
      };
    });
  };
  // const fileChangedHandler = async (e) => {
  //   const formData = new FormData();
  //   formData.append("image", e.target.files[0]);
  //   const imageUpload = await uploadImageOns3(formData);
  //   if (imageUpload.code == 200) {
  //     setInputs((input) => ({
  //       ...input,
  //       ["image"]: imageUpload.image_path,
  //     }));
  //   }
  // };
  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["profile_image"]: e.target.files[0],
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", inputs.name);
    formData.append("email", inputs.email);
    if (!state) {
      formData.append("password", inputs.password);
    }
    formData.append("status", inputs.status);
    if (typeof inputs.profile_image == "object") {
      formData.append("profile_image", inputs.profile_image);
    }
    setIsLoading(true);
    const result =
      type == "edit"
        ? await UpdateMemeber(formData, state._id)
        : await AddMemeber(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (state) {
      setInputs(state);
    }
  }, [state]);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex ">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-2">{`${
            type == "edit" ? "Edit" : "Add"
          } Member`}</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                fullWidth
                name="name"
                required
                value={inputs.name}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
                required
                type="email"
                value={inputs.email}
                onChange={handleChange}
              />
            </div>{" "}
            {!state && (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  name="password"
                  required
                  type="password"
                  value={inputs.password}
                  onChange={handleChange}
                />
              </div>
            )}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  label="Status *"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <div className="row w-100 div-style ms-0 pt-0">
                <div className="col-5">
                  <p className="">Upload Image *</p>
                  <FormHelperText className="pt-0">
                    Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {file ? (
                    <img src={file} height="50" />
                  ) : (
                    <>
                      {inputs.profile_image && (
                        <img
                          src={picBaseUrl + inputs.profile_image}
                          height="50"
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Button
                      startIcon={<FileUploadIcon />}
                      component="span"
                      className="capitalized "
                      style={{ border: "2px solid", padding: "10px" }}
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
              {inputs.profile_image.name == "" ? null : (
                <p className="text-secondary">{inputs.profile_image.name}</p>
              )}
            </div>
            <div className="text-end ms-auto">
              <Button variant="contained" type="submit">
                {type == "edit" ? "Update" : "Submit"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOrUpdateUsers;
